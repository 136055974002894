import {provideStore} from "@ngxs/store";
import {environment} from "environments/environment";
import {withNgxsFormPlugin} from "@ngxs/form-plugin";
import {withNgxsReduxDevtoolsPlugin} from "@ngxs/devtools-plugin";
import {importProvidersFrom, LOCALE_ID} from "@angular/core";
import {provideRouter} from "@angular/router";
import {provideInterfaceSize} from "@12stz/ui-kit/tokens";
import {provideToast} from "@12stz/ui-kit/organisms/toast";
import {AuthGuard, AuthSdkModule} from "@12stz/auth-sdk";
import {init} from '@sentry/angular-ivy';
import {createSentryConfig, SentryModule} from "configuration";
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {provideAnimations} from "@angular/platform-browser/animations";
import localeRu from '@angular/common/locales/ru';
import {registerLocaleData} from "@angular/common";

init(createSentryConfig(environment.production));

const shellRoute = {
  path: '',
  canActivate: [AuthGuard],
  data: { roles: ['access'] },
  loadChildren: () =>
    import('./app/shell/shell.module').then((m) => m.ShellModule),
}

const authSdkConfig = {
  clientId: environment.clientId,
  authServerUrl: environment.authServerUrl,
  debug: {
    enabled: false,
    roles: ['access']
  }
};

registerLocaleData(localeRu);
export const providers = [
  importProvidersFrom(
    AuthSdkModule.forRoot(authSdkConfig),
    SentryModule.forRoot(environment.production),
  ),
  provideStore([], { developmentMode: !environment.production}),
  withNgxsFormPlugin(),
  withNgxsReduxDevtoolsPlugin(),
  provideInterfaceSize('medium'),
  provideToast({
    vertical: 'top',
    horizontal: 'center',
  }),
  provideRouter([shellRoute]),
  provideAnimations(),
  provideHttpClient(withInterceptorsFromDi()),
  {
    provide: LOCALE_ID,
    useValue: 'ru',
  }
]
